import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button, Collapse } from "antd";
import { Menu } from "antd/lib";
import { PageHeader } from "@ant-design/pro-layout";
import { getSelfWebUrl } from "util/selfWeb";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { colors, grey } from "constants/colors";
import { useCompany } from "hooks/useCompany";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";

import { DarkThemePreviewImage } from "./DarkThemePreviewImage";
import { MobileOrderWelcomeScreenSettings } from "./MobileOrderWelcomeScreenSettings";
import {
  useMobileOrderDesignSettingsGetSettingsQuery,
  useMobileOrderThemeSettingsSaveSettingsMutation,
} from "./queries";
import { MobileOrderThemeSettings } from "./ThemeSettings";
import { WelcomeScreenPreviewIframe } from "./WelcomeScreenPreviewIframe";

const StyledMenu = styled(Menu)`
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 50px;
`;

const MobileOrderDesignPreviewWidth = 300;
const MobileOrderDesignPreviewHeight = 632;
const PreviewRightMargin = 20;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding-right: ${MobileOrderDesignPreviewWidth + PreviewRightMargin}px;
`;

const PreviewWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: ${PreviewRightMargin}px;
  width: ${MobileOrderDesignPreviewWidth}px;
  max-height: ${MobileOrderDesignPreviewHeight}px;
`;

const StyledCollapse = styled(Collapse)`
  width: ${MobileOrderDesignPreviewWidth + 2 /* antdの内部的なスタイルの理由で2pxを足す */}px;
  background-color: ${colors.Other.Header};
  border-radius: 0px;

  .ant-collapse-item {
    .ant-collapse-header {
      color: ${colors.Text.OnColor};
    }

    .ant-collapse-content {
      border-radius: 0px !important;

      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }
`;

const FormWrapper = styled.div`
  background-color: ${grey[0]};
  padding: 24px;
  margin-bottom: 24px;
`;

const SectionWrapper = styled.div<{ isActive: boolean }>`
  ${({ isActive }) => (isActive ? "" : "display: none;")}
`;

export type MobileOrderDesignSettingsFormValues = {
  themeSetting: "dark" | "light";
  welcomeMessage: string | null;
  backgroundImgSrc: string | null;
};

export const MobileOrderDesignSettingsFormItem =
  createFormItem<MobileOrderDesignSettingsFormValues>();

enum PageStateKeys {
  theme = "theme",
  welcome = "welcome",
}

export const mobileOrderThemeSettingsUrl = `/mobileOrderDesign/${PageStateKeys.theme}`;
const mobileOrderThemeSettingsWelcomeUrl = `/mobileOrderDesign/${PageStateKeys.welcome}`;

export const MobileOrderDesignSettings = () => {
  const [form] = Form.useForm<MobileOrderDesignSettingsFormValues>();

  const { state: pageState } = useParams<{ state: PageStateKeys }>();
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const { shop } = useShopPerCorporation();
  const shopId = shop?.shopId;

  const {
    data,
    loading: loadingDesignSettings,
    refetch: refetchSettings,
  } = useMobileOrderDesignSettingsGetSettingsQuery(
    shopId && companyId
      ? {
          variables: {
            shopId,
            companyId,
          },
        }
      : {
          skip: true,
        },
  );

  const shopData = data?.shop_by_pk;

  const collapseItems = useMemo(
    () => [
      {
        key: "preview",
        label: "プレビュー",
        style: { borderRadius: 0 },
        children:
          pageState === PageStateKeys.theme ? (
            <DarkThemePreviewImage form={form} />
          ) : (
            <WelcomeScreenPreviewIframe
              form={form}
              title="ウェルカム画面プレビュー"
              src={getSelfWebUrl(`/preview/${shopId}/welcome`)}
              width={MobileOrderDesignPreviewWidth}
              height={MobileOrderDesignPreviewHeight}
              shopName={shopData?.name ?? null}
            />
          ),
      },
    ],
    [form, pageState, shopData, shopId],
  );

  const [saveSettingsMutation] = useMobileOrderThemeSettingsSaveSettingsMutation();

  const initialValues = useMemo<MobileOrderDesignSettingsFormValues>(
    () => ({
      themeSetting: data?.company[0]?.enableDarkTheme ?? true ? "dark" : "light",
      welcomeMessage: data?.mobileOrderWelcomeScreenSetting[0]?.welcomeMessage ?? null,
      backgroundImgSrc: data?.mobileOrderWelcomeScreenSetting[0]?.imageUrl ?? null,
    }),
    [data],
  );

  useEffect(() => {
    form.setFieldsValue({
      themeSetting: initialValues.themeSetting,
      welcomeMessage: initialValues.welcomeMessage,
      backgroundImgSrc: initialValues.backgroundImgSrc,
    });
  }, [form, initialValues]);

  const clearChanges = useCallback(() => {
    form.setFieldsValue({
      themeSetting: initialValues.themeSetting,
      welcomeMessage: initialValues.welcomeMessage,
      backgroundImgSrc: initialValues.backgroundImgSrc,
    });
  }, [form, initialValues]);

  const handleSubmit = useCallback(async () => {
    if (!companyId) return;

    const {
      themeSetting: darkThemeSetting,
      welcomeMessage,
      backgroundImgSrc: imageUrl,
    } = form.getFieldsValue();

    try {
      await saveSettingsMutation({
        variables: {
          companyId,
          enableDarkTheme: darkThemeSetting === "dark",
          welcomeScreenSetting: {
            companyId,
            welcomeMessage,
            imageUrl,
          },
        },
      });
      message.success("更新が成功しました");
      clearChanges();
      await refetchSettings();
    } catch (error) {
      message.error("設定の更新に失敗しました");
    }
  }, [companyId, form, saveSettingsMutation, refetchSettings, clearChanges]);

  return (
    <>
      <DashboardLayout title="モバイルオーダーデザイン設定">
        <ContentWrapper>
          <PageHeader title="モバイルオーダーデザイン設定" />
          <StyledMenu selectedKeys={[pageState ?? ""]} mode="horizontal" disabledOverflow>
            <Menu.Item
              key={PageStateKeys.theme}
              onClick={() => navigate(mobileOrderThemeSettingsUrl)}
            >
              メニュー画面
            </Menu.Item>
            <Menu.Item
              key={PageStateKeys.welcome}
              onClick={() => navigate(mobileOrderThemeSettingsWelcomeUrl)}
            >
              チェックイン画面
            </Menu.Item>
          </StyledMenu>

          <Form
            name="mobileOrderDesignSettings"
            form={form}
            layout="vertical"
            initialValues={initialValues}
          >
            <FormWrapper>
              <SectionWrapper isActive={pageState === PageStateKeys.theme}>
                <MobileOrderThemeSettings />
              </SectionWrapper>
              <SectionWrapper isActive={pageState === PageStateKeys.welcome}>
                <MobileOrderWelcomeScreenSettings />
              </SectionWrapper>
            </FormWrapper>
            <Spacer size={10} />

            <MobileOrderDesignSettingsFormItem.NonProperty noStyle shouldUpdate>
              {({ getFieldsValue }) => {
                const {
                  themeSetting: darkThemeSetting,
                  welcomeMessage,
                  backgroundImgSrc,
                } = getFieldsValue();

                const isEnableDarkThemeChanged =
                  (darkThemeSetting === "dark") !== data?.company[0]?.enableDarkTheme;
                const isWelcomeMessageChanged =
                  welcomeMessage !== data?.mobileOrderWelcomeScreenSetting[0]?.welcomeMessage;
                const isBackgroundImageChanged =
                  backgroundImgSrc !== data?.mobileOrderWelcomeScreenSetting[0]?.imageUrl;

                const hasChanges =
                  isEnableDarkThemeChanged || isWelcomeMessageChanged || isBackgroundImageChanged;
                return (
                  <FormActions>
                    <Button onClick={clearChanges} disabled={!hasChanges}>
                      キャンセル
                    </Button>
                    <Button type="primary" onClick={handleSubmit} disabled={!hasChanges}>
                      更新
                    </Button>
                  </FormActions>
                );
              }}
            </MobileOrderDesignSettingsFormItem.NonProperty>
          </Form>
        </ContentWrapper>
      </DashboardLayout>
      {
        // NOTE: MOのウェルカム画面がまだなので 1.170.x にはウェルカム画面のプレビューを表示しない
        pageState === PageStateKeys.welcome ? null : (
          <PreviewWrapper>
            <StyledCollapse defaultActiveKey={["preview"]} items={collapseItems} />
          </PreviewWrapper>
        )
      }
    </>
  );
};

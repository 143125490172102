import React, { memo } from "react";
import styled from "styled-components";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { CurrentSales } from "../types";
import { currentSalesDefaultPageSize } from "..";

const formatter = new Intl.NumberFormat("ja");

const getFormattedMonetaryAmount = (amount: number | null) =>
  amount === null ? "-" : !isFinite(amount) ? "¥0" : `¥${formatter.format(Number(amount))}`;

const calcPercentage = ({
  top,
  bottom,
  digit = 1, // NOTE: 表示する少数の桁数
}: {
  top: number;
  bottom: number;
  digit?: number;
}): number => (bottom !== 0 ? Number(((top / bottom) * 100).toFixed(digit)) : 0);

const calcDivision = ({ top, bottom }: { top: number; bottom: number }): number =>
  bottom !== 0 ? Math.floor(Number(top / bottom)) : 0;

const StyledColumnTitle = styled.div`
  text-align: center;
  width: 100%;
`;

const PercentageText = styled.div<{ percentage: number }>`
  color: ${({ percentage }) =>
    isFinite(percentage) ? (percentage >= 1 ? "#52C41A" : "#FF4D4F ") : "unset"};
`;

type Props = {
  loading?: boolean;
  currentSaleses: CurrentSales[];
  totalShopCount: number;
  showTaxIncluded: boolean;
  onChangePage: ({ page }: { page: number }) => void;
};

export const CurrentSalesTable = memo<Props>(
  ({ loading, currentSaleses, totalShopCount, showTaxIncluded, onChangePage }) => {
    const [pagination, setPagination] = usePagination();

    const columns: ColumnsType<CurrentSales> = [
      {
        title: <StyledColumnTitle>店舗名</StyledColumnTitle>,
        dataIndex: "shopName",
        width: 130,
        align: "left",
        render(_: unknown, { shopName }: CurrentSales) {
          return shopName;
        },
      },
      {
        title: <StyledColumnTitle>売上</StyledColumnTitle>,
        width: 160,
        align: "right",
        render(_: unknown, { currentSales, currentTaxExcludedSales }: CurrentSales) {
          return getFormattedMonetaryAmount(
            showTaxIncluded ? currentSales : currentTaxExcludedSales,
          );
        },
      },
      {
        title: <StyledColumnTitle>売上目標</StyledColumnTitle>,
        width: 160,
        align: "right",
        render(_: unknown, { salesBudgetAmount }: CurrentSales) {
          return getFormattedMonetaryAmount(salesBudgetAmount ?? null);
        },
      },
      {
        title: <StyledColumnTitle>目標達成率</StyledColumnTitle>,
        width: 120,
        align: "right",
        render(
          _: unknown,
          { salesBudgetAmount, currentSales, currentTaxExcludedSales }: CurrentSales,
        ) {
          const sales = showTaxIncluded ? currentSales : currentTaxExcludedSales;
          return salesBudgetAmount !== undefined && salesBudgetAmount !== null ? (
            <PercentageText percentage={sales / salesBudgetAmount}>
              {calcPercentage({ top: sales, bottom: salesBudgetAmount })}%
            </PercentageText>
          ) : (
            "-"
          );
        },
      },
      {
        title: <StyledColumnTitle>客数</StyledColumnTitle>,
        width: 120,
        align: "right",
        render(_: unknown, { currentNumberOfPeople }: CurrentSales) {
          return currentNumberOfPeople;
        },
      },
      {
        title: <StyledColumnTitle>客単価</StyledColumnTitle>,
        width: 120,
        align: "right",
        render(_: unknown, { currentUnitSales }: CurrentSales) {
          return `${currentUnitSales}円`;
        },
      },
      {
        title: <StyledColumnTitle>利用中の客数</StyledColumnTitle>,
        width: 120,
        align: "right",
        render(_: unknown, { activeNumberOfPeople }: CurrentSales) {
          return activeNumberOfPeople;
        },
      },
      {
        title: <StyledColumnTitle>利用中の組数</StyledColumnTitle>,
        width: 120,
        align: "right",
        render(_: unknown, { activeNumberOfTableUser }: CurrentSales) {
          return activeNumberOfTableUser;
        },
      },
      {
        title: <StyledColumnTitle>満卓率</StyledColumnTitle>,
        width: 120,
        align: "right",
        render(_: unknown, { activeNumberOfTableUser, tableCount }: CurrentSales) {
          return `${calcPercentage({
            top: activeNumberOfTableUser,
            bottom: tableCount,
          })}%`;
        },
      },
    ];

    const onTableChange = ({ position: _, ...pagination }: TablePaginationConfig) => {
      onChangePage({ page: pagination.current ?? 1 });
      setPagination(pagination);
    };

    return (
      <Table
        rowKey="shopName"
        columns={columns}
        dataSource={currentSaleses}
        loading={loading}
        bordered
        size="middle"
        pagination={{
          ...pagination,
          defaultPageSize: currentSalesDefaultPageSize,
          showSizeChanger: false,
          total: totalShopCount,
        }}
        onChange={onTableChange}
      />
    );
  },
);

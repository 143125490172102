import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";

import { Loading } from "components/Loading";
import { grey } from "constants/colors";

import { MobileOrderDesignSettingsFormValues } from ".";

const Container = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${grey[4]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIframe = styled.iframe<{ width: number; height: number }>`
  border: none;
  pointer-events: none;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: 0;
  margin: 0;
`;

type Props = {
  form: FormInstance<MobileOrderDesignSettingsFormValues>;
  shopName: string | null;
  title: string;
  src: string;
  width: number;
  height: number;
};

type WelcomeScreenPreviewIframePayload =
  | {
      shopName: string;
    }
  | {
      welcomeMessage: string | null;
    }
  | {
      backgroundImgSrc: string | null;
    };

const postIframeMessage = ({
  iframe,
  payload,
}: {
  iframe: HTMLIFrameElement;
  payload: WelcomeScreenPreviewIframePayload;
}) => {
  iframe.contentWindow?.postMessage(JSON.stringify(payload), "*");
};

export const WelcomeScreenPreviewIframe = ({
  form,
  shopName,
  title,
  src,
  width,
  height,
}: Props) => {
  const welcomeMessage: MobileOrderDesignSettingsFormValues["welcomeMessage"] = useWatch(
    "welcomeMessage",
    form,
  );
  const backgroundImgSrc: MobileOrderDesignSettingsFormValues["backgroundImgSrc"] = useWatch(
    "backgroundImgSrc",
    form,
  );

  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const messageQueueRef = useRef<WelcomeScreenPreviewIframePayload[]>([]);

  const handleIframeLoaded = useCallback(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    // NOTE: post all messages that came while waiting for the iframe to load
    messageQueueRef.current.forEach((payload) =>
      postIframeMessage({
        iframe,
        payload,
      }),
    );

    // NOTE: we posted all messages, so clear the queue
    messageQueueRef.current = [];

    setIsIframeLoading(false);
  }, []);

  const queueOrSendMessage = useCallback(
    (payload: WelcomeScreenPreviewIframePayload) => {
      if (iframeRef.current && !isIframeLoading) {
        postIframeMessage({
          iframe: iframeRef.current,
          payload,
        });
        return;
      }

      // NOTE: if the iframe is not yet loaded, queue the data for later
      messageQueueRef.current.push(payload);
    },
    [isIframeLoading],
  );

  useEffect(() => {
    if (!shopName) return;
    queueOrSendMessage({
      shopName,
    });
  }, [queueOrSendMessage, shopName]);

  useEffect(() => {
    queueOrSendMessage({
      welcomeMessage,
    });
  }, [queueOrSendMessage, welcomeMessage]);

  useEffect(() => {
    queueOrSendMessage({
      backgroundImgSrc,
    });
  }, [queueOrSendMessage, backgroundImgSrc]);

  return (
    <Container width={width} height={height}>
      <StyledIframe
        ref={iframeRef}
        title={title}
        src={src}
        width={width}
        height={height}
        onLoad={handleIframeLoaded}
      />
      {isIframeLoading ? (
        <LoadingOverlay>
          <Loading size="large" />
        </LoadingOverlay>
      ) : null}
    </Container>
  );
};

import React, { memo } from "react";
import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { formatPrice } from "util/price";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { UnreachableError } from "libs/unreachable";
import { DepositCycleTypeEnum } from "types/graphql";

// ref: https://github.com/dinii-inc/dinii-self-all/blob/9ea43136bacfe7a2e18d79d5fd6b538a173448f9/packages/dinii-self-backend/src/modules/online-payment/entities/gmo-payment-remittance-result-status/gmo-payment-remittance-result-status.entity.ts#L6
const gmoPaymentRemittanceResultStatusType = [
  "init",
  "submitted",
  "registered",
  "remittanceDataCreated",
  "remittanceDataCreateFailed",
  "remittanceCompleted",
  "remittanceFailed",
  "canceled",
] as const;

type GmoPaymentRemittanceResultStatusType = (typeof gmoPaymentRemittanceResultStatusType)[number];

const getRemittanceResultLabel = (
  code: GmoPaymentRemittanceResultStatusType,
): { label: string; color: string } => {
  switch (code) {
    // NOTE: 細かいステータスは顧客には見せず、これ以外で失敗したケースはチームでキャッチし手動リトライする
    // それまでは処理中として表示する
    case "remittanceCompleted":
      return { label: "送金済み", color: "green" };
    case "remittanceFailed":
      return { label: "送金失敗", color: "red" };
    case "init":
    case "submitted":
    case "registered":
    case "remittanceDataCreated":
    case "remittanceDataCreateFailed":
    case "canceled":
      return { label: "処理中です", color: "" };
    default:
      throw new UnreachableError(code);
  }
};

export type DepositHistoryByShop = {
  shopName: string;
  startingDate: string;
  closingDate: string;
  totalAmount: number;
  totalNetAmount: number;
  totalFeeAmount: number;
  depositCycle: string;
  remittanceStatus: string;
  remittedAt: string;
  closingDepositId: string;
};

type Props = {
  loading: boolean;
  accountName: string;
  depositHistories: DepositHistoryByShop[];
};

const createColumns = (): ColumnsType<DepositHistoryByShop> => [
  {
    title: "店舗名",
    align: "center",
    width: 100,
    dataIndex: "shopName",
  },
  {
    title: "起算日",
    align: "center",
    width: 100,
    render(_: string, { startingDate }) {
      return dayjs(startingDate).format("YYYY/MM/DD");
    },
  },
  {
    title: "締め日",
    align: "center",
    width: 100,
    render(_: string, { closingDate }) {
      return dayjs(closingDate).format("YYYY/MM/DD");
    },
  },
  {
    title: "入金額",
    align: "right",
    width: 100,
    render(_: string, { totalAmount }) {
      return formatPrice(totalAmount);
    },
  },
  {
    title: "売上",
    align: "right",
    width: 100,
    render(_: string, { totalNetAmount }) {
      return formatPrice(totalNetAmount);
    },
  },
  {
    title: "手数料",
    align: "right",
    width: 100,
    render(_: string, { totalFeeAmount }) {
      return formatPrice(totalFeeAmount);
    },
  },
  {
    title: "入金サイクル",
    align: "center",
    width: 100,
    render(_: string, { depositCycle }) {
      return (
        <Tag>
          {depositCycle === DepositCycleTypeEnum.TwiceAMonth
            ? "月2回"
            : depositCycle === DepositCycleTypeEnum.SixAMonth
            ? "月6回"
            : ""}
        </Tag>
      );
    },
  },
  {
    title: "入金ステータス",
    align: "center",
    width: 100,
    render(_: string, { remittanceStatus }) {
      const meta = getRemittanceResultLabel(
        remittanceStatus as GmoPaymentRemittanceResultStatusType,
      );
      if (!meta) return null;
      return <Tag color={meta.color}>{meta.label}</Tag>;
    },
  },
];

export const AdyenPaymentDepositHistoryTable = memo<Props>(({ loading, depositHistories }) => {
  const [pagination, setPagination] = usePagination();

  const columns = createColumns();

  return (
    <Table
      rowKey="closingDepositId"
      columns={columns}
      dataSource={depositHistories}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});

import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { Alert } from "antd";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useAnalytics } from "hooks/useAnalytics";
import { useCorporation } from "hooks/useCorporation";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";
import { FeatureFlagEnum } from "types/graphql";

import { CustomerDashboardCondition } from "./CustomerDashboardCondition";
import { CustomerDashboardCumulativeCustomerIdCount } from "./CustomerDashboardCumulativeCustomerIdCount";
import { CustomerDashboardKpiPanels } from "./CustomerDashboardKpiPanels";
import { CustomerDashboardTransitions } from "./CustomerDashboardTransitions";
import {
  useCustomerDashboardsGetCumulativeCountQuery,
  useCustomerDashboardsGetCustomerDashboardKpisQuery,
  useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery,
  useCustomerDashboardsGetCustomerFaveYellCountRankingQuery,
  useCustomerDashboardsGetCustomerViralCountRankingQuery,
  useCustomerDashboardsGetCustomerVisitedCountRankingQuery,
  useCustomerDashboardsGetShopsQuery,
  useCustomerDashboardsGetTransitionsQuery,
} from "./queries";
import { useTargetDate } from "./useTargetDate";

const RANKING_TOP_CUSTOMER_COUNT = 10;

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  width: 1168px;
  margin: 0 auto;
`;

// FIXME: Discovery 環境で BigQuery が動かないため、実店舗ではクエリを止める
const isDiscoveryProductionCorporation = (corporationId: string | null) =>
  import.meta.env.APP_ENV === "discovery" &&
  corporationId !== "4622d38f-a0fe-4425-a517-bff4dfa6867c";

export const CustomerDashboards = () => {
  const { logEvent } = useAnalytics();

  const { targetDate, handleChangeDate } = useTargetDate();

  const { shop: currentShop } = useShopPerCorporation();

  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId ?? null;

  const {
    data: getShopsData,
    loading: getShopsDataLoading,
    error: getShopsDataError,
  } = useCustomerDashboardsGetShopsQuery(
    corporationId && !isDiscoveryProductionCorporation(corporationId)
      ? { variables: { corporationId } }
      : { skip: true },
  );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );

  const {
    data: getCumulativeCountData,
    loading: getCumulativeCountDataLoading,
    error: getCumulativeCountDataError,
  } = useCustomerDashboardsGetCumulativeCountQuery(
    currentShop && !isDiscoveryProductionCorporation(corporationId)
      ? {
          variables: { input: { shopId: currentShop.shopId } },
        }
      : { skip: true },
  );

  const {
    data: getCustomerDashboardKpisData,
    loading: getCustomerDashboardKpisDataLoading,
    error: getCustomerDashboardKpisDataError,
  } = useCustomerDashboardsGetCustomerDashboardKpisQuery(
    currentShop && targetDate && !isDiscoveryProductionCorporation(corporationId)
      ? {
          variables: {
            input: {
              shopId: currentShop.shopId,
              targetDate: targetDate.format("YYYY-MM-DD"),
            },
          },
        }
      : { skip: true },
  );

  const {
    data: getTransitionsData,
    loading: getTransitionsDataLoading,
    error: getTransitionsDataError,
  } = useCustomerDashboardsGetTransitionsQuery(
    currentShop && targetDate && !isDiscoveryProductionCorporation(corporationId)
      ? {
          variables: {
            input: {
              shopId: currentShop.shopId,
              targetDate: targetDate.format("YYYY-MM-DD"),
            },
          },
        }
      : { skip: true },
  );

  const {
    data: getCustomerVisitedCountRankingData,
    loading: getCustomerVisitedCountRankingDataLoading,
    error: getCustomerVisitedCountRankingDataError,
  } = useCustomerDashboardsGetCustomerVisitedCountRankingQuery(
    currentShop && !isDiscoveryProductionCorporation(corporationId)
      ? {
          variables: { input: { shopId: currentShop.shopId, limit: RANKING_TOP_CUSTOMER_COUNT } },
        }
      : { skip: true },
  );

  const {
    data: getCustomerViralCountRankingData,
    loading: getCustomerViralCountRankingDataLoading,
    error: getCustomerViralCountRankingDataError,
  } = useCustomerDashboardsGetCustomerViralCountRankingQuery(
    currentShop && !isDiscoveryProductionCorporation(corporationId)
      ? {
          variables: { input: { shopId: currentShop.shopId, limit: RANKING_TOP_CUSTOMER_COUNT } },
        }
      : { skip: true },
  );

  const {
    data: getCustomerFaveYellAmountRankingData,
    loading: getCustomerFaveYellAmountRankingDataLoading,
    error: getCustomerFaveYellAmountRankingDataError,
  } = useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery(
    currentShop && !isDiscoveryProductionCorporation(corporationId)
      ? {
          variables: { input: { shopId: currentShop.shopId, limit: RANKING_TOP_CUSTOMER_COUNT } },
        }
      : { skip: true },
  );

  const {
    data: getCustomerFaveYellCountRankingData,
    loading: getCustomerFaveYellCountRankingDataLoading,
    error: getCustomerFaveYellCountRankingDataError,
  } = useCustomerDashboardsGetCustomerFaveYellCountRankingQuery(
    currentShop && !isDiscoveryProductionCorporation(corporationId)
      ? {
          variables: { input: { shopId: currentShop.shopId, limit: RANKING_TOP_CUSTOMER_COUNT } },
        }
      : { skip: true },
  );

  const { isFeatureAvailable } = useFeatureFlag();

  const isYellAvailable = currentShop
    ? isFeatureAvailable({ name: FeatureFlagEnum.Yell, shopIds: [currentShop.shopId] })
    : false;

  const shouldShowError =
    getShopsDataError ||
    getCumulativeCountDataError ||
    getCustomerDashboardKpisDataError ||
    getTransitionsDataError ||
    getCustomerVisitedCountRankingDataError ||
    getCustomerViralCountRankingDataError ||
    getCustomerFaveYellAmountRankingDataError ||
    getCustomerFaveYellCountRankingDataError;

  useEffect(() => {
    if (currentShop) logEvent({ type: "customer_dashboard_view" });
  }, [currentShop, logEvent]);

  return (
    <DashboardLayout title="顧客一覧">
      {shouldShowError && (
        <StyledAlert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <Wrapper>
        <CustomerDashboardCondition
          companies={companies}
          targetDate={targetDate}
          handleChangeDate={handleChangeDate}
          loading={getShopsDataLoading}
        />

        <CustomerDashboardCumulativeCustomerIdCount
          cumulativeCount={getCumulativeCountData?.cumulativeCount}
          loading={getCumulativeCountDataLoading}
        />

        <CustomerDashboardKpiPanels
          kpisData={getCustomerDashboardKpisData?.customerDashboardKpis}
          loading={getCustomerDashboardKpisDataLoading}
          isYellAvailable={isYellAvailable}
        />

        <CustomerDashboardTransitions
          transitions={getTransitionsData?.customerDashboardTransitions}
          transitionsLoading={getTransitionsDataLoading}
          customerVisitedCountRankingData={
            getCustomerVisitedCountRankingData?.customerVisitedCountRanking
          }
          customerVisitedCountRankingDataLoading={getCustomerVisitedCountRankingDataLoading}
          customerViralCountRankingData={
            getCustomerViralCountRankingData?.customerViralCountRanking
          }
          customerViralCountRankingDataLoading={getCustomerViralCountRankingDataLoading}
          customerFaveYellAmountRankingData={
            getCustomerFaveYellAmountRankingData?.customerFaveYellAmountRanking
          }
          customerFaveYellAmountRankingDataLoading={getCustomerFaveYellAmountRankingDataLoading}
          customerFaveYellCountRankingData={
            getCustomerFaveYellCountRankingData?.customerFaveYellCountRanking
          }
          customerFaveYellCountRankingDataLoading={getCustomerFaveYellCountRankingDataLoading}
          isYellAvailable={isYellAvailable}
        />
      </Wrapper>
    </DashboardLayout>
  );
};

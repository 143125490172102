import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategory = gql`
    query EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategory($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
  adyenPaymentStoreMerchantCategory(where: {shopId: {_eq: $shopId}}, limit: 1) {
    shopId
    merchantCategory
  }
}
    `;
export const EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategory = gql`
    mutation EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategory($input: AdyenPaymentCreateStoreMerchantCategoryInput!) {
  onlinePaymentRoot {
    createAdyenPaymentStoreMerchantCategory(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )>, adyenPaymentStoreMerchantCategory: Array<(
    { __typename?: 'adyenPaymentStoreMerchantCategory' }
    & Pick<Types.AdyenPaymentStoreMerchantCategory, 'shopId' | 'merchantCategory'>
  )> }
);

export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables = Types.Exact<{
  input: Types.AdyenPaymentCreateStoreMerchantCategoryInput;
}>;


export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { createAdyenPaymentStoreMerchantCategory: (
      { __typename?: 'AdyenPaymentCreateStoreMerchantCategoryResult' }
      & Pick<Types.AdyenPaymentCreateStoreMerchantCategoryResult, 'result'>
    ) }
  )> }
);


export const EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryDocument = gql`
    query EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategory($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
  adyenPaymentStoreMerchantCategory(where: {shopId: {_eq: $shopId}}, limit: 1) {
    shopId
    merchantCategory
  }
}
    `;

/**
 * __useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery__
 *
 * To run a query within a React component, call `useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery(baseOptions: Apollo.QueryHookOptions<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>(EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryDocument, options);
      }
export function useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>(EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryDocument, options);
        }
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery>;
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryLazyQueryHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryLazyQuery>;
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryResult = Apollo.QueryResult<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>;
export const EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryDocument = gql`
    mutation EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategory($input: AdyenPaymentCreateStoreMerchantCategoryInput!) {
  onlinePaymentRoot {
    createAdyenPaymentStoreMerchantCategory(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationFn = Apollo.MutationFunction<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>;

/**
 * __useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation__
 *
 * To run a mutation, you first call `useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, { data, loading, error }] = useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>(EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryDocument, options);
      }
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation>;
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationResult = Apollo.MutationResult<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation>;
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationOptions = Apollo.BaseMutationOptions<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>;
import React, { memo } from "react";
import styled from "styled-components";
import TextArea from "antd/es/input/TextArea";

import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";

import { MobileOrderDesignSettingsFormItem } from "..";

const HelpText = styled.div`
  color: gray;
`;

export const MobileOrderWelcomeScreenSettings = memo(() => (
  <>
    <span>背景の設定</span>
    <Spacer size={12} />

    <MobileOrderDesignSettingsFormItem name="backgroundImgSrc">
      <MobileOrderDesignSettingsFormItem.NonProperty noStyle shouldUpdate>
        {({ getFieldValue, setFields }) => {
          const imageNamePath = ["backgroundImgSrc"];
          const imageUrl: string = getFieldValue(imageNamePath);
          return (
            <ImageField
              image={imageUrl}
              setUploadImage={(image) => setFields([{ name: imageNamePath, value: image }])}
              uploadImageApiKey="mobileOrderWelcomeScreenSettings"
              formName="mobileOrderWelcomeScreenSettingsForm"
              isMovieAccepted
              height={395}
              width={195}
              bottomHelpText="最大アップロードサイズ：10MB"
            />
          );
        }}
      </MobileOrderDesignSettingsFormItem.NonProperty>
    </MobileOrderDesignSettingsFormItem>
    <label htmlFor="welcomeMessage">メッセージの表示</label>
    <HelpText>設定したメッセージは業態ごとに表示されます</HelpText>
    <Spacer size={12} />
    <MobileOrderDesignSettingsFormItem name="welcomeMessage">
      <TextArea rows={2} maxLength={90} showCount placeholder="メッセージ" />
    </MobileOrderDesignSettingsFormItem>
  </>
));

import React, { useMemo } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";
import { isNotNullable } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useGmoBankAccountDetails } from "hooks/useGmoBankAccountDetails";

import { AdyenPaymentDepositFilter, FilterConditions } from "./AdyenPaymentDepositFilter";
import { AdyenPaymentDepositHistoryTable } from "./AdyenPaymentDepositHistoryTable";
import { useAdyenPaymentDepositHistoryByRemittance } from "./useAdyenPaymentDepositHistoryByRemittance";

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const getInitialTimeRange = () => {
  const now = dayjs();
  return {
    from: now.subtract(3, "month"),
    to: now,
  };
};

export const AdyenPaymentDepositHistory = () => {
  const [corporation] = useCorporation();

  const { filterConditions, updateFilterCondition } = useFilterConditions<FilterConditions>({
    gmoBankAccountId: undefined,
    ...getInitialTimeRange(),
  });

  const {
    depositHistoriesByRemittance,
    loading: loadingDepositHistory,
    error: getDepositHistoryError,
    gmoBankAccountIds,
  } = useAdyenPaymentDepositHistoryByRemittance({
    corporationId: corporation?.corporationId,
    remittedAtFrom: filterConditions.from ?? undefined,
    remittedAtTo: filterConditions.to ?? undefined,
  });

  const {
    getGmoBankAccountDetail,
    loading: loadingGmoBankAccountDetails,
    error: getGmoBankAccountDetailsError,
  } = useGmoBankAccountDetails({
    gmoBankAccountIds,
  });

  const loading = loadingDepositHistory || loadingGmoBankAccountDetails;
  const error = getDepositHistoryError || getGmoBankAccountDetailsError;

  const depositHistoriesWithGmoBankAccount = useMemo(
    () =>
      depositHistoriesByRemittance
        .map((depositHistory) => {
          const gmoBankAccount = getGmoBankAccountDetail(depositHistory.gmoBankAccountId);
          if (!gmoBankAccount) {
            return;
          }
          return {
            ...depositHistory,
            ...depositHistory.payoutJobSchedule,
            bankAccountName: gmoBankAccount.accountNumber,
          };
        })
        .filter(isNotNullable)
        .sort((a, b) => dayjs(b.remittedAt).diff(dayjs(a.remittedAt))),
    [depositHistoriesByRemittance, getGmoBankAccountDetail],
  );

  const filteredDepositHistories = useMemo(
    () =>
      depositHistoriesWithGmoBankAccount.filter(
        (depositHistory) =>
          !filterConditions.gmoBankAccountId ||
          depositHistory.gmoBankAccountId === filterConditions.gmoBankAccountId,
      ),
    [depositHistoriesWithGmoBankAccount, filterConditions],
  );

  return (
    <DashboardLayout title="入金履歴">
      <PageHeader
        title="入金履歴"
        footer={
          <HeaderContainer>
            <SelectorContainer></SelectorContainer>
          </HeaderContainer>
        }
      />
      <AdyenPaymentDepositFilter
        gmoBankAccountIds={gmoBankAccountIds}
        getGmoBankAccountDetail={getGmoBankAccountDetail}
        filterConditions={filterConditions}
        updateFilterCondition={updateFilterCondition}
      />
      <Spacer size={24} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AdyenPaymentDepositHistoryTable
        loading={loading}
        depositHistories={filteredDepositHistories}
      />
    </DashboardLayout>
  );
};

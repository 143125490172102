import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditWinboardMenuFormItem } from "../useEditWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CodeField = memo<Props>((props) => (
  <EditWinboardMenuFormItem
    label="メニューコード"
    name="code"
    rules={[
      { required: true, pattern: /^[1-9]\d{0,7}$/, message: "8桁以内の数字で入力してください" },
    ]}
    {...props}
  >
    <InputCode />
  </EditWinboardMenuFormItem>
));

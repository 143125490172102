import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { ApolloError } from "@apollo/client";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopCashlessSettingHeader } from "components/PageHeader/ShopCashlessSettingHeader";

import { AddShopCashlessSettingMerchantCategoryFormValues } from "./AddShopCashlessSettingMerchantCategoryForm/useAddShopCashlessSettingMerchantCategoryForm";
import { AddShopCashlessSettingMerchantCategoryForm } from "./AddShopCashlessSettingMerchantCategoryForm";
import {
  useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation,
  useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery,
} from "./queries";

export const EditShopCashlessSettingProviderSetting = () => {
  const { id } = useParams<{ id: string }>();

  const { data, error, loading } =
    useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery(
      id ? { variables: { shopId: id } } : { skip: true },
    );
  const shop = useMemo(() => data?.shop_by_pk ?? null, [data]);
  const merchantCategory = useMemo(
    () => data?.adyenPaymentStoreMerchantCategory[0] ?? null,
    [data],
  );

  const [
    createAdyenPaymentStoreMerchantCategory,
    { loading: loadingCreateAdyenPaymentStoreMerchantCategory },
  ] = useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation();

  const onSubmitMerchantCategoryForm = useCallback(
    async (values: AddShopCashlessSettingMerchantCategoryFormValues) => {
      if (!id) return;

      try {
        await createAdyenPaymentStoreMerchantCategory({
          variables: {
            input: { ...values, shopId: id },
          },
        });
        message.success("作成に成功しました");
      } catch (e) {
        if (e instanceof ApolloError) {
          message.error(e.message);
          return;
        }
        message.error("作成に失敗しました");
      }
    },
    [id, createAdyenPaymentStoreMerchantCategory],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name ?? ""}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "決済店舗" }],
      }}
    >
      <ShopCashlessSettingHeader shop={shop} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop && (
        <AddShopCashlessSettingMerchantCategoryForm
          merchantCategory={merchantCategory ?? null}
          loading={loadingCreateAdyenPaymentStoreMerchantCategory}
          onSubmit={onSubmitMerchantCategoryForm}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
